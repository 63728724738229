<template>
  <div class="Enter-box">
    <div class="desktop">
      <div class="Essential-box">
        <ul class="Essential-ul">
          <li class="Essential-li">桌台管理</li>
          <li @click="structure()" class="">归口管理</li>
          <li @click="TOzhuotaiquyu()">区域管理</li>



        </ul>
        <div class="shop_choice">
          <span>选择店铺：</span>
          <!-- :class="abc[index] ? 'shopStyleType' : 'shopNoneType'" -->
          <el-select v-model="value" placeholder="请选择" class="Essential-store" @change='shop_boxS'>
            <el-option v-for="(item) in options" :key="item.shop_id" :label="item.shop_name" :value="item.shop_name">
              <span style="float: left;font-size:14px;">{{item.shop_name}}</span>
              <span style="float:right;" v-show="item.flag_table === '0'">未设置</span>
              <span class="red_span" style="float:right;" v-show="item.flag_table === '1'">已设置</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="Essential-structure">
        <div class="Essential-structure-box">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="大厅" name="first">
              <div class="desktop-deskS">
                <div class="desktop-switchover">
                  <el-radio-group v-model="radio">
                    <el-radio :label="1">小桌</el-radio>
                    <el-radio :label="2">中桌</el-radio>
                    <el-radio :label="3">大桌</el-radio>
                  </el-radio-group>


                  <el-button style="margin-left:20px" size="small" round type="primary" @click="downImg">下载二维码
                  </el-button>
                </div>
                <div class="desktop-desk deskS" v-show="radio == 1">
                  <div class="deskS-box">
                    <ul v-for="(item,index) in teapoy" :key="index">
                      <li>
                        <ul>
                          <li>
                            <p>小桌 {{item.table_name}}</p><span>人数&nbsp;{{item.cust_num}}</span>
                          </li>
                          <li class="deskS-box-li">
                            <h3 @click="Modify_tables(item)">修改桌台</h3>
                            <p @click="cancel(item)">x</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul @click="newly_table()" class="newly-table">
                      <img src="../../../assets/img/tianjiaS.png" alt="">
                      <span>新增桌台</span>
                    </ul>

                  </div>
                </div>
                <div class="desktop-desk deskS" v-show="radio == 2">
                  <div class="deskS-box">
                    <ul v-for="(item,index) in middle" :key="index">
                      <li>
                        <ul>
                          <li>
                            <p>中桌 {{item.table_name}}</p><span>人数&nbsp;{{item.cust_num}}</span>
                          </li>
                          <li class="deskS-box-li">
                            <h3 @click="Modify_tables(item)">修改桌台</h3>
                            <p @click="cancel(item)">x</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul @click="newly_table()" class="newly-table">
                      <img src="../../../assets/img/tianjiaS.png" alt="">
                      <span>新增桌台</span>
                    </ul>

                  </div>
                </div>
                <div class="desktop-desk deskS" v-show="radio == 3">
                  <div class="deskS-box">
                    <ul v-for="(item,index) in Ohashi" :key="index">
                      <li>
                        <ul>
                          <li>
                            <p>大桌 {{item.table_name}}</p><span>人数&nbsp;{{item.cust_num}}</span>
                          </li>
                          <li class="deskS-box-li">
                            <h3 @click="Modify_tables(item)">修改桌台</h3>
                            <p @click="cancel(item)">x</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul @click="newly_table()" class="newly-table">
                      <img src="../../../assets/img/tianjiaS.png" alt="">
                      <span>新增桌台</span>
                    </ul>

                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="包厢" name="second">
              <div class="desktop-deskS">
                <div class="desktop-desk deskS">
                  <div class="deskS-box">
                    <ul v-for="(item,index) in box_box" :key="index">
                      <li>
                        <ul>
                          <li>
                            <p>包厢 {{item.table_name}}</p><span>人数&nbsp;{{item.cust_num}}</span>
                          </li>
                          <li class="deskS-box-li">
                            <h3 @click="Modify_box(item)">修改包厢</h3>
                            <p @click="cancelS(item)">x</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul class="newly-table" @click="newly_tableS()">
                      <img src="../../../assets/img/tianjiaS.png" alt="">
                      <span>新增桌台</span>
                    </ul>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="newlyTable" v-if="newlyTable">
          <p>新增桌台</p>
          <span @click="close" class="newlyTable-sapn">
            X
          </span>
          <el-tabs v-model="activeNameS" type="card" @tab-click="handleClickS">
            <el-tab-pane v-if="revamp_t" label="大厅" name="first">
              <el-radio-group v-model="radioS" class="newlyTable-radio">
                <el-radio :label="1" :disabled="radioS != 1 ? true : false">小桌</el-radio>
                <el-radio :label="2" :disabled="radioS != 2 ? true : false">中桌</el-radio>
                <el-radio :label="3" :disabled="radioS != 3 ? true : false">大桌</el-radio>
              </el-radio-group>
              <div>
                <el-form class="" ref="form" :model="form" label-width="80px">
                  <el-form-item label="桌号">
                    <el-input type="text" placeholder="请输入桌号" v-model="form.table">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="标准人数">
                    <el-input type="text" placeholder="请输入人数" v-model="form.selling">
                    </el-input>
                  </el-form-item>
                </el-form>
                <el-button @click="lobby()" type="danger" v-if="lobbyNewly">确定</el-button>
                <el-button @click="alter_lobbyS()" type="danger" v-if="alter_lobby">修改</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="revamp_b" label="包厢" name="second">
              <div>
                <el-form class="" ref="formDa" :model="formDa" label-width="80px">
                  <el-form-item label="包厢名称">
                    <el-input type="text" placeholder="请输入包厢名称" v-model="formDa.table">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="标准人数">
                    <el-input type="text" placeholder="请输入人数" v-model="formDa.selling">
                    </el-input>
                  </el-form-item>
                </el-form>
                <el-button @click="box" type="danger" v-if="lobbyNewly">确定</el-button>
                <el-button @click="box_x()" type="danger" v-if="alter_lobby">修改</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="shade" @click="giabain()" v-if="shade"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    query_shop_info_list,
    insert_table_info,
    query_table_info_list,
    have_exist_table_name,
    delete_table_info,
    update_table_info,
    query_shop_status_list_of_table
  } from '@api/pulic'

  export default {
    data() {
      return {
        revamp_t: true,
        revamp_b: true,

        shade: false,
        newlyTable: false,
        radio: 1,
        radioS: 1,
        num: '10',
        tabPosition: 'left',
        activeName: 'first',
        activeNameS: 'first',
        form: {
          selling: '',
          table: ''
        },
        options: {},
        formDa: {
          selling: '',
          table: ''
        },
        detaProducadd: {
          nature: ''
        },
        value: '',
        Vent_id: '',
        Vshop_id: '',

        teapoy: [], // 小桌渲染
        middle: [], // 中桌渲染
        Ohashi: [], // 大桌渲染
        box_box: [], // 包厢渲染

        alter_lobby: false, // 修改桌台按钮
        lobbyNewly: true, // 添加桌台按钮

        table_idS: '',
        thisVent_id: '',
        thisShop_id: '',

        abc: [],
        downImgList: []
      }
    },
    created() {
      var that = this;
      this.desk_refresh()
      query_shop_info_list({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        if (res.code == 200) {
          let dianpu = [];
          res.body.data.forEach(item => {
            dianpu.push(item.shop_id)
          })
          dianpu.forEach((item, index) => {
            query_table_info_list({
              data: {
                ent_id: this.$store.state.ent_id,
                shop_id: item
              }
            }).then(res => {
              console.log(res, '555555')
              if (res.code == 200) {
                that.abc[index] = res.body.data.length;
              }
            })
          })
          query_table_info_list({
            data: {
              ent_id: this.$store.state.ent_id,
              shop_id: res.body.data[0].shop_id,
            }
          }).then(ress => {
            console.log(ress)
            let arr = []
            for (const i in ress.body.data) {
              arr.push({
                img: ress.body.data[i].qrcode,
                name: ress.body.data[i].table_name
              })
            }

            this.downImgList = arr
            console.log(this.downImgList)
          })
        }
      })
    },
    watch: {
      radio() {
        this.shop_boxS(this.value)
      }
    },
    methods: {
      downloadIamge(imgsrc, name) { //下载图片地址和图片名
        let image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          let context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
          let a = document.createElement("a"); // 生成一个a元素
          let event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgsrc;
      },
      downImg() {

        let basURL = ''
        if(process.env.NODE_ENV === 'development'){ 
            //开发环境 do something
            basURL = 'https://cscyb.meilibaobiao.com:8443/cbreat/V1.0.0/export_table_qrcode_zip'
        }else{
            //生产环境 do something
            basURL = 'https://cyb.meilibaobiao.com:8443/cbreat/V1.0.0/export_table_qrcode_zip'
        }


        window.open(
          // `${basURL}?ent_id=${this.Vent_id}&shop_id=${this.Vshop_id}`
          `https://cscyb.meilibaobiao.com:8443/cbreat/V1.0.0/export_table_qrcode_zip?ent_id=${this.Vent_id}&shop_id=${this.Vshop_id}`
          )
 
      },
      // 选择店铺
      shop_boxS(event) {
        const a = this.options.find((item) => {
          if (item.shop_name == event) {
            return item
          }
        })
        this.Vent_id = a.ent_id
        this.Vshop_id = a.shop_id
        console.log(this.Vshop_id)
        console.log(this.Vent_id)
        // 桌台列表 小桌
        if (this.radio == 1) {
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '1'
            }
          }).then(res => {
            console.log(res)
            this.teapoy = res.body.data
          })
          this.radioS = 1
        }
        // 桌台列表 中桌
        if (this.radio == 2) {
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '2'
            }
          }).then(res => {
            console.log(res)
            this.middle = res.body.data
          })
          this.radioS = 2
        }
        // 桌台列表 大桌
        if (this.radio == 3) {
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '3'
            }
          }).then(res => {
            console.log(res)
            this.Ohashi = res.body.data
          })
          this.radioS = 3
        }

        if (this.activeName != this.second) {
          console.log('123123122312312')
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '0'
            }
          }).then(res => {
            console.log(res, 'asdasdasdasdasdsadasd')
            console.log(this.Vent_id, '\\\\\\\\\\asdasdasdasdasdsadasd')
            this.box_box = res.body.data

          })
        }


        query_table_info_list({
          data: {
            ent_id: this.Vent_id,
            shop_id: this.Vshop_id,
          }
        }).then(res => {
          console.log(res)
          let arr = []
          for (const i in res.body.data) {
            arr.push({
              img: res.body.data[i].qrcode,
              name: res.body.data[i].table_name
            })
          }
          this.downImgList = arr
          console.log(this.downImgList)
        })
      },

      // 小桌删除          得写四个
      cancel(item) {
        console.log(item)
        console.log(item.cust_num)
        this.$confirm('此操作将永久删除该桌台, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delete_table_info({
            data: {
              ent_id: item.ent_id,
              shop_id: item.shop_id,
              table_id: item.table_id,
              table_name: item.table_name,
              user_id: sessionStorage.getItem('user_id')
            }
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            }
            this.shuaxin() // 刷新桌台
            this.shaun()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },

      // 包厢删除
      cancelS(item) {
        this.$confirm('此操作将永久删除该包厢, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          delete_table_info({
            data: {
              ent_id: item.ent_id,
              shop_id: item.shop_id,
              table_id: item.table_id,
              table_name: item.table_name,
              user_id: sessionStorage.getItem('user_id')
            }
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })

            }
            this.shuaxinS() // 刷新包厢

            this.shaun()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      // 大厅新增
      lobby() {
        if (this.form.table != '' && this.form.selling != '') {
          if (this.form.table != '') {
            have_exist_table_name({
              data: {
                ent_id: this.Vent_id,
                shop_id: this.Vshop_id,
                table_name: this.form.table
              }
            }).then(reX => {
              console.log(reX)
              if (reX.code == 600) {
                this.$message({
                  message: '您输入的桌台已存在',
                  type: 'error'
                })
              } else if (reX.code == 700) {
                insert_table_info({
                  data: {
                    ent_id: this.Vent_id, // 企业id
                    shop_id: this.Vshop_id, // 桌台id
                    table_name: this.form.table, // 桌台号
                    table_type: this.radioS.toString(), //
                    cust_num: this.form.selling
                  }
                }).then(res => {
                  if (res.code ==200) {
                    console.log(res)
                    // 关闭添加包厢
                    this.shade = false
                    this.newlyTable = false
                    // 清空桌台号
                    this.form.table == ''
                    this.form.selling == ''
                    this.form.table = '' // 桌号
                    this.form.selling = '' // 人数
                    // this.desk_refresh()
                    this.shuaxin() // 刷新
                    this.shaun()
                    this.$message({
                      message: '您成功添加桌台',
                      type: 'error'
                    }) 
                  }
                if (res.code =='1200'||res.code =='1100') {
                  this.$message({
                    message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                    type: 'error'
                  })
                }
                })
              }
            })
          }
        } else {
          this.$message({
            message: '请您完善添加桌台信息',
            type: 'success'
          })
        }
        console.log(this.radioS)
        console.log(this.form.table)
        console.log(this.form.selling)
      },

      // 包厢新增包厢
      box() {
        if (this.formDa.table != '' && this.formDa.selling != '') {
          if (this.formDa.table != '') {
            // 查询是否有包厢
            have_exist_table_name({
              data: {
                ent_id: this.Vent_id,
                shop_id: this.Vshop_id,
                table_name: this.formDa.table
              }
            }).then(reX => {
              console.log(reX)
              if (reX.code == 600) {
                this.$message({
                  message: '您输入的包厢已存在',
                  type: 'error'
                })
              } else if (reX.code == 700) {
                // 提交包厢
                insert_table_info({
                  data: {
                    ent_id: this.Vent_id, // 企业id
                    shop_id: this.Vshop_id, // 桌台id
                    table_name: this.formDa.table, // 桌台号
                    table_type: '0', //
                    cust_num: this.formDa.selling
                  }
                }).then(res => {
                  if (res.code == 200) {
                    this.$message({
                      message: '添加包厢成功',
                      type: 'message'
                    })
                    // 关闭添加包厢
                    this.shade = false
                    this.newlyTable = false
                    // 清空桌台号
                    this.formDa.table == ''
                    this.formDa.selling == ''
                    // this.desk_refresha()
                    // this.shaun()
                    this.shuaxinS()
                  }
                  if (res.code =='1200'||res.code =='1100') {
                    this.$message({
                      message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                      type: 'error'
                    })
                  }
                })
              }
            })
          } else {}
        } else {
          this.$message({
            message: '请您完善添加桌台信息',
            type: 'error'
          })
        }
      },

      // 桌台修改显示
      Modify_tables(item) {
        this.shade = true // 遮罩层
        this.newlyTable = true // 新增显示
        this.alter_lobby = true // 显示修改按钮
        this.lobbyNewly = false // 隐藏添加按钮
        this.form.table = item.table_name // 桌号
        this.form.selling = item.cust_num // 人数

        this.table_idS = item.table_id // 存桌台id
        this.thisVent_id = item.ent_id // 存企业id
        this.thisShop_id = item.shop_id,

          this.revamp_t = true
        this.revamp_b = false

      },

      // 包厢修改显示
      Modify_box(item) {
        this.shade = true // 遮罩层
        this.newlyTable = true // 新增显示
        this.alter_lobby = true // 显示修改按钮
        this.lobbyNewly = false // 隐藏添加按钮
        console.log(item)
        this.formDa.table = item.table_name // 桌号
        this.formDa.selling = item.cust_num // 人数

        this.table_idS = item.table_id // 存桌台id
        this.thisVent_id = item.ent_id // 存企业id
        this.thisShop_id = item.shopv_id
        this.revamp_t = false
        this.revamp_b = true

      },

      // 修改桌子
      alter_lobbyS() {
        if (this.form.selling == '' && this.form.table == '') {
          this.$message({
            type: 'success',
            message: '请您完善添加桌台信息'
          })
        } else {
          update_table_info({
            data: {
              ent_id: this.thisVent_id,
              user_id: sessionStorage.getItem('user_id'),
              shop_id: this.thisShop_id,
              table_id: this.table_idS,
              table_name: this.form.table,
              table_type: this.radio.toString(),
              cust_num: this.form.selling
            }
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.shade = false
              this.newlyTable = false
              this.shuaxin() // 刷新桌台
            }
            if (res.code =='1200'||res.code =='1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            }
          })
        }
      },

      // 修改包厢
      box_x() {
        console.log(111)
        if (this.formDa.selling == '' && this.formDa.table == '') {
          this.$message({
            type: 'success',
            message: '请您完善添加桌台信息'
          })
        } else {
          update_table_info({
            data: {
              ent_id: this.thisVent_id,
              user_id: sessionStorage.getItem('user_id'),
              shop_id: this.thisShop_id,
              table_id: this.table_idS,
              table_name: this.formDa.table,
              table_type: '0',
              cust_num: this.formDa.selling
            }
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '您修改包厢完成'
              })
              this.shade = false
              this.newlyTable = false
              query_table_info_list({
                data: {
                  ent_id: this.Vent_id,
                  shop_id: this.Vshop_id,
                  table_type: '0'
                }
              }).then(res => {
                console.log(res, 'asdasdasdasdasdsadasd')
                console.log(this.Vent_id, '\\\\\\\\\\asdasdasdasdasdsadasd')
                this.box_box = res.body.data
                this.desk_refresha()
              })
            }
            if (res.code =='1200'||res.code =='1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            }
          })
        }
      },
      // 添加桌台关闭
      giabain() {
        this.shade = false
        this.newlyTable = false

        this.revamp_t = true
        this.revamp_b = true
      },
      // 大厅添加显示
      newly_table() {
        this.shade = true
        this.newlyTable = true
        this.formDa.table = '' // 桌号
        this.formDa.selling = '' // 人数
        this.form.table = '' // 桌号
        this.form.selling = '' // 人数
        this.alter_lobby = false // 显示修改按钮
        this.lobbyNewly = true // 隐藏添加按钮、

        this.revamp_t = true
        this.revamp_b = true

      },
      // 包厢添加显示
      newly_tableS() {
        this.shade = true
        this.newlyTable = true
        this.form.table = '' // 桌号
        this.formDa.table = '' // 桌号
        this.formDa.selling = '' // 人数
        this.form.selling = '' // 人数
        this.alter_lobby = false // 显示修改按钮
        this.lobbyNewly = true // 隐藏修改按钮、

        this.revamp_t = true
        this.revamp_b = true
      },
      // 关闭新增桌台
      close() {
        this.shade = false
        this.newlyTable = false
        this.revamp_t = true
        this.revamp_b = true
      },
      
      structure() {
        this.$router.push('/AttributeManagement')
      },
      TOzhuotaiquyu(){
        this.$router.push('/zhuotaiquyu')
      },
      // Essential() {
      //   this.$router.push('/DishManagement')
      // },
      handleClick(tab, event) {
        if (this.activeName != this.second) {
          console.log('123123122312312')
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '0'
            }
          }).then(res => {
            console.log(res, 'asdasdasdasdasdsadasd')
            console.log(this.Vent_id, '\\\\\\\\\\asdasdasdasdasdsadasd')
            this.box_box = res.body.data

          })
        }

        //
        if (this.activeName == 'second') {
          this.activeNameS = 'second'
          // console.log(this.activeNameS,'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb');
        }
        if (this.activeName == 'first') {
          this.activeNameS = 'first'
          //  console.log(this.activeNameS,'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
        }
      },
      handleClickS(tab, event) {
        console.log(tab, event)
      },


      shaun() {
        query_shop_status_list_of_table({
          data: {
          ent_id: this.$store.state.ent_id
          }
        }).then(res => {
          console.log(res, '1---1---1---1---1---')
          this.options = res.body.data
        })
      },

      // 选择数据
      desk_refresh() {
        query_shop_status_list_of_table({
          data: {
            ent_id: this.$store.state.ent_id
          }
        }).then(res => {

          console.log(res, '12221222122212221')
          console.log(res.body.data)
          this.options = res.body.data
          this.Vent_id = res.body.data[0].ent_id
          this.Vshop_id = res.body.data[0].shop_id
          this.value = res.body.data[0].shop_name
          console.log(this.Vent_id)
          console.log(this.Vshop_id)

          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '1'
            }
          }).then(res => {
            console.log(res)
            this.teapoy = res.body.data
          })
        })
      },

      // 包厢
      desk_refresha() {
        query_shop_status_list_of_table({
          data: {
            ent_id: this.$store.state.ent_id
          }
        }).then(res => {
          console.log(res)
          console.log(res.body.data)
          this.options = res.body.data
          this.Vent_id = res.body.data[0].ent_id
          this.Vshop_id = res.body.data[0].shop_id
          this.value = res.body.data[0].shop_name
          console.log(this.Vent_id)
          console.log(this.Vshop_id)
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '0'
            }
          }).then(res => {
            console.log(res)
            this.box_box = res.body.data
          })
        })
      },

      // 刷新桌台
      shuaxin() {
        // 桌台列表 小桌
        if (this.radio == 1) {
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '1'
            }
          }).then(res => {
            console.log(res)
            this.teapoy = res.body.data
          })
        }
        // 桌台列表 中桌
        if (this.radio == 2) {
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '2'
            }
          }).then(res => {
            console.log(res)
            this.middle = res.body.data
          })
        }
        // 桌台列表 大桌
        if (this.radio == 3) {
          query_table_info_list({
            data: {
              ent_id: this.Vent_id,
              shop_id: this.Vshop_id,
              table_type: '3'
            }
          }).then(res => {
            console.log(res)
            this.Ohashi = res.body.data
          })
        }
        // 包厢

      },

      // 刷新包厢
      shuaxinS() {
        query_table_info_list({
          data: {
            ent_id: this.Vent_id,
            shop_id: this.Vshop_id,
            table_type: '0'
          }
        }).then(res => {
          console.log(res)
          this.box_box = res.body.data
        })
      }
    }
  }
</script>

<style scoed lang="scss">
  .shop_choice_p {
    font-size: 16px;
    display: flex;
    margin: 52px 30px 0 0;

    p {
      display: flex;

      span {
        border-radius: 50%;
        margin: 2px 5px 0 5px;
      }
    }

    &>p:first-of-type {
      margin-right: 6px;

      span {
        width: 14px;
        height: 14px;
        background: #FC4353;
        display: inline-block;
      }

      color: #FC4353;
    }

    &>p:last-of-type {
      span {
        width: 14px;
        display: inline-block;
        height: 14px;
        background: #ccc;
      }

      i {
        color: #ccc;
      }
    }
  }

  .red_span {
    color: #FC4353;
  }

  .shop_choice {
    display: flex;

    &>span {
      color: #666666;
      margin: 50px 10px 0 0;
      font-size: 18px;
    }
  }

  .shopStyleType span,
  .shopNoneType span {
    position: relative;
  }

  .shopNoneType span::before {
    content: '';
    width: 10px;
    right: -15px;
    position: absolute;
    top: 4px;
    height: 10px;
    background: #ccc;
    border-radius: 50%;
    display: block;
  }

  .shopStyleType span::before {
    content: '';
    width: 10px;
    right: -15px;
    position: absolute;
    top: 4px;
    height: 10px;
    background: #FC4353;
    border-radius: 50%;
    display: block;
  }

  .el-main {

    background: #FFFFFF;
  }

  .desktop {
    width: 100%;
    color: #f6f6f3;
    background: url('../../../assets/img/beijing.png') no-repeat;
    // height: 800px;
    background-size: 100% 100%;
    padding-bottom: 60px;

    .Essential-box {
      display: flex;
      justify-content: space-between;

      .Essential-ul {
        display: flex;
        padding: 58px 0 0 97px;

        li {
          font-size: 20px;
          color: #666666;
          width: 280px;
          font-weight: 500;
        }

        .Essential-li {
          color: #FE5745;
        }
      }
    }

    .desktop-deskS {
      padding: 0 100px 0 100px;
      box-sizing: border-box;

      .desktop-desk {
        .deskS-box {
          display: flex;
          flex-wrap: wrap;
          // height: 534px;
          overflow-y: auto;

          .newly-table {
            width: 180px;
            height: 100px;
            background: #FFFFFF;
            border: 1px dashed #FC4353;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            padding-top: 40px;

            img {
              margin-right: 10px;
              width: 22px;
              height: 22px;
            }
          }

          &>ul {
            // display: flex;
            // flex-wrap:wrap;
            margin-top: 40px;

            &>li {
              width: 180px;
              height: 100px;
              background: #FFFFFF;
              border: 1px solid #FC4353;
              border-radius: 15px;
              text-align: center;
              margin: 0 20px 20px 0;
              position: relative;

              p {
                color: #FC4353;
                font-size: 16px;
                width: 96%;
                overflow: hidden; //
                text-overflow: ellipsis; //
                white-space: nowrap; //
                margin: 14px 0 8px 0;
              }

              span {
                color: #FC4353;
                font-size: 16px;
                display: block;
                margin-bottom: 4px;
              }

              .deskS-box-li {
                display: flex;
                justify-content: center;

                p {
                  font-size: 24px;
                  color: #fff;
                  position: absolute;
                  background: #FC4353;
                  width: auto;
                  top: -25px;
                  width: 30px;
                  height: 30px;
                  line-height: 26px;
                  text-align: center;
                  border-radius: 50%;
                  right: -13px;
                }

                h3 {
                  color: #333;
                  font-size: 13px;
                  margin-top: 6px;
                  width: 60px;
                  height: 26px;
                  line-height: 26px;
                  background: rgba(252, 67, 83, .4);
                  border-radius: 6px;
                  color: #fff;
                  text-align: center;

                  &:first-of-type {
                    margin-right: 10px;
                  }

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  .newlyTable {
    width: 580px;
    height: 500px;
    background: #FFFFFF;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 23;
    box-sizing: border-box;
    // overflow-x: auto
    padding: 33px;

    // position: relative;
    .newlyTable-sapn {
      width: 48px;
      height: 49px;
      background: linear-gradient(43deg, #FC4353, #FF5E41);
      color: #fff;
      line-height: 49px;
      text-align: center;
      font-size: 24px;
      position: absolute;
      right: -16px;
      top: -16px;

      :hover {
        cursor: pointer;
      }

      border-radius: 50%;
    }

    p {
      font-size: 20px;
      text-align: center;
      color: #333;
      margin-bottom: 34px;
    }

    .newlyTable-radio {
      margin-top: 32px;
      display: flex;
      justify-content: center;
    }
  }
</style>